import { defineStore } from 'pinia';

export const useModalStore = defineStore('modalStore', {
  state: () => ({
    showModal: false,
    imageShow: false,  // State to manage image visibility
  }),
  actions: {
    openModal() {
      this.toggleImage(true);  // Show images when the modal opens
      this.showModal = true;
    },
    closeModal() {
      this.toggleImage(false);  // Hide images when the modal closes
      this.showModal = false;
    },
    toggleImage(show) {
      this.imageShow = show;
    }
  }
});